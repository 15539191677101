import React, { useState, useEffect } from "react";
import s from "./skill.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { add_skill_api, update_skill_api } from "../api/livedarshan";

const AddLiveDarshans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [titleEnglish, settitleEnglish] = useState();
  const [titleHindi, settitleHindi] = useState();
  const [descEnglish, setdescEnglish] = useState();
  const [descHindi, setdescHindi] = useState();
  const [url, seturl] = useState();
  const [thumbnail, setthumbnail] = useState();
  const [embeddedUrl, setembeddedUrl] = useState("");
  const [path, setpath] = useState();
  const [tags, settags] = useState();

  const pagetype = location?.state?.pagetype;
  const id = location?.state?.data?.id;
  useEffect(() => {
    settitleEnglish(location?.state?.data?.titleEnglish);
    settitleHindi(location?.state?.data?.titleHindi);
    setdescEnglish(location?.state?.data?.descEnglish);
    setembeddedUrl(location?.state?.data?.embeddedUrl);
    setdescHindi(location?.state?.data?.descHindi);
    seturl(location?.state?.data?.url);
    setthumbnail(location?.state?.data?.thumbnail?.url);
    setpath(location?.state?.data?.location);
    settags(location?.state?.data?.tags);
  }, [location]);

  const create_event = async () => {
    if (pagetype == "Add") {
      try {
        const fd = new FormData();
        fd.append("titleEnglish", titleEnglish);
        fd.append("titleHindi", titleHindi);
        fd.append("descEnglish", descEnglish);
        fd.append("descHindi", descHindi);
        fd.append("url", url);
        fd.append("embeddedUrl", embeddedUrl);
        fd.append("thumbnail", thumbnail);
        fd.append("tags", tags);
        fd.append("location", path);

        let res = await add_skill_api(fd);
        if (res.data.status) {
          navigate("/live-darshans");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("descEnglish", descEnglish);
      fd.append("descHindi", descHindi);
      fd.append("url", url);
      fd.append("embeddedUrl", embeddedUrl);
      fd.append("thumbnail", thumbnail);
      fd.append("tags", tags);
      fd.append("location", path);

      try {
        let res = await update_skill_api(location?.state?.data?._id, fd);
        if (res.data.status) {
          console.log(res);
          navigate("/live-darshans");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title English</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleEnglish"
                  value={titleEnglish}
                  onChange={(e) => settitleEnglish(e.target.value)}
                  placeholder="Enter Title English"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleHindi"
                  value={titleHindi}
                  onChange={(e) => settitleHindi(e.target.value)}
                  placeholder="Enter Title Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc English</label>
                <input
                  type="text"
                  className="form-control"
                  name="descEnglish"
                  value={descEnglish}
                  onChange={(e) => setdescEnglish(e.target.value)}
                  placeholder="Enter desc English"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="descHindi"
                  value={descHindi}
                  onChange={(e) => setdescHindi(e.target.value)}
                  placeholder="Enter desc Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">URL</label>
                <input type="text" className="form-control" name="url" value={url} onChange={(e) => seturl(e.target.value)} placeholder="Enter url" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Embedded Url</label>
                <input
                  type="text"
                  className="form-control"
                  name="embeddedUrl"
                  value={embeddedUrl}
                  onChange={(e) => setembeddedUrl(e.target.value)}
                  placeholder="Embedded url"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Location</label>
                <input type="text" className="form-control" name="path" value={path} onChange={(e) => setpath(e.target.value)} placeholder="Location" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Tags</label>
                <input type="text" className="form-control" name="Tags" value={tags} onChange={(e) => settags(e.target.value)} placeholder="Enter tags" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Thumbnail (URL)</label>
                <input
                  type="text"
                  className="form-control"
                  name="thumbnail"
                  value={thumbnail}
                  onChange={(e) => setthumbnail(e.target.value)}
                  placeholder="Enter thumbnail (URL)"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_event()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddLiveDarshans;
