import React from "react";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/profile";
import Category from "./pages/FollowUp";
import Banner from "./pages/Banner";
import AddBanner from "./pages/AddBanner";
import Testimonial from "./pages/Testimonial";
import AddTestimonial from "./pages/AddTestimonial";
import AddAstrologer from "./pages/AddAstrologer";
import { LiveDarshansList } from "./pages/LiveDarshansList";
import LiveDarshansAdd from "./pages/LiveDarshansAdd";
import LibraryMediaList from "./components/LibraryMedia/LibraryMediaList";
import AddLibraryMedia from "./components/LibraryMedia/AddLibraryMedia";
import Event from "./pages/Event";
import AppDataEdit from "./components/AppData/AppDataEdit";
import AdminList from "./components/Admin/Admin";
import AddAdmin from "./components/Admin/AddAdmin";
import Leads from "./pages/Lead";
import LeadAdd from "./pages/LeadAdd";
import AllocatedLeadList from "./components/AllocatedLead/AllocatedLead";
import AddAllocatedLead from "./components/AllocatedLead/AddAllocatedLead";
import FollowUp from "./pages/FollowUp";
import AddFollowPage from "./pages/AddFollowPage";
import Action from "./components/FollowUp/ActionPage";
import ActionPage from "./components/FollowUp/ActionPage";
import FollowUpHistory from "./components/FollowUp/FollowUpHistory";
import ReportList from "./components/Report/ReportList";

const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "leads", component: <Leads /> },
  { params: "dashboard", component: <Dashboard /> },
  { params: "add-lead", component: <LeadAdd /> },
  { params: "profile", component: <Profile /> },
  { params: "live-darshans", component: <LiveDarshansList /> },
  { params: "add-live-darshans", component: <LiveDarshansAdd /> },
  { params: "library-media", component: <LibraryMediaList /> },
  { params: "add-library-media", component: <AddLibraryMedia /> },
  { params: "followup-list", component: <FollowUp /> },
  { params: "add-folowup", component: <AddFollowPage /> },
  { params: "banner-list", component: <Banner /> },
  { params: "add-banner", component: <AddBanner /> },
  { params: "testimonial-list", component: <Testimonial /> },
  { params: "testimonial-add", component: <AddTestimonial /> },
  { params: "event-list", component: <Event /> },
  { params: "astrologer-add", component: <AddAstrologer /> },

  // Admin Listing
  { params: "admin-list", component: <AdminList /> },
  { params: "add-feature-media", component: <AddAdmin /> },

  // Allocated Lead Listing
  { params: "allocate-lead-list", component: <AllocatedLeadList /> },
  { params: "add-tag", component: <AddAllocatedLead /> },

  //App Data
  { params: "app-data", component: <AppDataEdit /> },
  // action page in follow up
  { params: "action-page", component: <ActionPage /> },
  { params: "history-page", component: <FollowUpHistory /> },

  // Report
  { params: "report-list", component: <ReportList /> }
];

export default routeArray;
