import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import s from "./dashboard.module.css";
import Graph from "./Graph";
import Graph1 from "./Graph1";
import { get_dash_list_api } from "../api/dashboard";
import Loder from "../../Loder/Loder";
const Home = () => {

  const [dashboardData, setDashboardData] = useState({})
  const [isLoading, setIsLoading] = useState(false)


  // const cardArray = [
  //   {
  //     title: "Total Lead",
  //     icon: "2994",
  //   },
  //   {
  //     title: "Active Lead",
  //     icon: "1500",
  //   },
  //   {
  //     title: "Success",
  //     icon: "1000",
  //   },
  //   {
  //     title: "Rejected",
  //     icon: "494",
  //   },
  // ];
  useEffect(() => {
    getAllDashFunc();
  }, [])

  const getAllDashFunc = async () => {
    setIsLoading(true)
    try {
      let res = await get_dash_list_api()
      if (res?.data?.status) {
        setDashboardData(res?.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        console.log("status false")
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  return (
    <>
      <div style={{ paddingInline: "1rem" }}>
        <div className="dashboard-section">
          <div className={s["dashboard-header-container"]}>
            {/* {dashboardData.map((data) => ( */}
            <Card className={s["dashbord-content"]}>
              <div class={s["dashboard-main-card"]}>
                <div class={s["dashboard-card-content"]}>
                  <div class={s["card-title"]}>
                    <h4>Total Lead</h4>
                    <h5>{dashboardData?.TotalLead}</h5>
                  </div>
                </div>
              </div>
            </Card>
            <Card className={s["dashbord-content"]}>
              <div class={s["dashboard-main-card"]}>
                <div class={s["dashboard-card-content"]}>
                  <div class={s["card-title"]}>
                    <h4>Active Lead</h4>
                    <h5>{dashboardData?.Activedata}</h5>
                  </div>
                </div>
              </div>
            </Card>
            <Card className={s["dashbord-content"]}>
              <div class={s["dashboard-main-card"]}>
                <div class={s["dashboard-card-content"]}>
                  <div class={s["card-title"]}>
                    <h4>Success</h4>
                    <h5>{dashboardData?.Successdata}</h5>
                  </div>
                </div>
              </div>
            </Card>
            <Card className={s["dashbord-content"]}>
              <div class={s["dashboard-main-card"]}>
                <div class={s["dashboard-card-content"]}>
                  <div class={s["card-title"]}>
                    <h4>Rejected</h4>
                    <h5>{dashboardData?.Rejecteddata}</h5>
                  </div>
                </div>
              </div>
            </Card>
            {/* ))} */}
          </div>
        </div>
        <div className={s["graph-container"]}>
          <div className={s["graph-content"]}>
            <div className="graph-left" style={{ width: "70%" }}>
              <Graph1 />
            </div>
            <div className="graph-right" style={{ width: "30%" }}>
              <Graph />
            </div>
          </div>
        </div>
      </div>
      <Loder loading={isLoading} />
    </>
  );
};

export default Home;
