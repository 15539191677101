import React from "react";
import AddLead from "../components/Lead/AddLead";
const LeadAdd = () => {
  return (
    <>
      <AddLead />
    </>
  );
};

export default LeadAdd;
