import React, { useState, useEffect } from "react";
import s from "./language.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addLanguage_api, updateLanguage_api } from "../api/language";
import RichTextEditor from "react-rte-17";

const AddLibraryMedia = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [titleEnglish, settitleEnglish] = useState("");
  const [titleHindi, settitleHindi] = useState("");
  const [descEnglish, setdescEnglish] = useState("");
  const [descHindi, setdescHindi] = useState("");
  const [url, seturl] = useState("");
  const [tags, settags] = useState("");
  const [thumbnail, setthumbnail] = useState("");
  const [rte, setrte] = useState(RichTextEditor.createEmptyValue());
  const [rte2, setrte2] = useState(RichTextEditor.createEmptyValue());

  const pagetype = location.state.pagetype;

  useEffect(() => {
    settitleEnglish(location?.state?.data?.titleEnglish);
    settitleHindi(location?.state?.data?.titleHindi);
    setdescEnglish(location?.state?.data?.descEnglish);
    setdescHindi(location?.state?.data?.descHindi);
    seturl(location?.state?.data?.url);
    setthumbnail(location?.state?.data?.thumbnail?.url);
    const initialEditorState = RichTextEditor.createValueFromString(
      location?.state?.data?.lyrics.hindi,
      "html"
    );
    const initialEditorState2 = RichTextEditor.createValueFromString(
      location?.state?.data?.lyrics.english,
      "html"
    );
    setrte(initialEditorState);
    setrte2(initialEditorState2);
  }, [location]);

  const onChange1 = (value) => {
    setrte(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  const onChange2 = (value) => {
    setrte2(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  const create_user = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("descEnglish", descEnglish);
      fd.append("descHindi", descHindi);
      fd.append("url", url);
      fd.append("mediaType", "audio");
      fd.append(
        "lyrics",
        JSON.stringify({
          hindi: rte.toString("html"),
          english: rte2.toString("html"),
        })
      );
      fd.append("tags", tags);
      fd.append("thumbnail", thumbnail);

      try {
        let res = await addLanguage_api(fd);
        if (res.data.status) {
          navigate("/library-media");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("titleEnglish", titleEnglish);
      fd.append("titleHindi", titleHindi);
      fd.append("descEnglish", descEnglish);
      fd.append("descHindi", descHindi);
      fd.append("url", url);
      fd.append("mediaType", "audio");
      fd.append(
        "lyrics",
        JSON.stringify({
          hindi: rte.toString("html"),
          english: rte2.toString("html"),
        })
      );
      fd.append("tags", tags);
      fd.append("thumbnail", thumbnail);
      try {
        let res = await updateLanguage_api(fd, location?.state?.data?._id);
        if (res.data.status) {
          navigate("/library-media");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title English</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleEnglish"
                  value={titleEnglish}
                  onChange={(e) => settitleEnglish(e.target.value)}
                  placeholder="Title English"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="titleHindi"
                  value={titleHindi}
                  onChange={(e) => settitleHindi(e.target.value)}
                  placeholder="Title Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc English</label>
                <input
                  type="text"
                  className="form-control"
                  name="descEnglish"
                  value={descEnglish}
                  onChange={(e) => setdescEnglish(e.target.value)}
                  placeholder="Desc English"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="descHindi"
                  value={descHindi}
                  onChange={(e) => setdescHindi(e.target.value)}
                  placeholder="Desc Hindi"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={url}
                  onChange={(e) => seturl(e.target.value)}
                  placeholder="url"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Tags</label>
                <input
                  type="text"
                  className="form-control"
                  name="tags"
                  value={tags}
                  onChange={(e) => settags(e.target.value)}
                  placeholder="Tags"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Thumbnail (URL)</label>
                <input
                  type="text"
                  className="form-control"
                  name="thumbnail"
                  value={thumbnail}
                  onChange={(e) => setthumbnail(e.target.value)}
                  placeholder="Thumbnail (URL)"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Lyrics (Hindi)</label>
                <RichTextEditor
                  value={rte}
                  className="employment_jobdescribee"
                  onChange={onChange1}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Lyrics (English)</label>
                <RichTextEditor
                  value={rte2}
                  className="employment_jobdescribee"
                  onChange={onChange2}
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddLibraryMedia;
