import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./followup.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";
import { getBaseUrl2 } from "../../utils";
import { BiArrowBack } from "react-icons/bi";
import { get_follow_up_history_api } from "../api/followup";

const FollowUpHistory = () => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");

    const [followUpHistory, setFollowUpHistory] = useState([]);
    const location = useLocation()
    console.log(followUpHistory, "Follow up History--------------------------------------------------------------------")


    useEffect(() => {
        fetchAllFollowUpHistoryFunc();
    }, [pageCount, search]);

    async function fetchAllFollowUpHistoryFunc() {
        setisLoading(true);
        try {
            const temp = {
                id: location?.state?.data._id,
                // page: pageCount,
                // limit: 5,
                // search: search.trim(),
            };
            let res = await get_follow_up_history_api(temp);
            if (res.data.status) {
                setFollowUpHistory(res?.data?.data)
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));


    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Follow Up List</h3>
                    </div>
                </div>
                <div className={s["title"]} onClick={() => navigate(-1)}>
                    <BiArrowBack />
                    Back
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Follow Up Date</StyledTableCell>
                            <StyledTableCell align="center">Remark</StyledTableCell>
                            <StyledTableCell align="center">Conversion Chances</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {followUpHistory?.[0]?.history?.map((row) => (
                            <StyledTableRow>
                                {/* <StyledTableCell>
                                    {row.image?.url ? <img style={{ height: "2rem", width: "3rem" }} src={getBaseUrl2() + row.image?.url} alt="" /> : null}
                                </StyledTableCell> */}

                                <StyledTableCell>{row?.date}</StyledTableCell>
                                <StyledTableCell>{row?.remarks} </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: "bold" }}>{(row?.conversion_chances).toUpperCase()} </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {/* {followUpHistory?.length <= 0 && <DataNotFound />} */}
                {/* {followUpHistory?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )} */}
            </div>

            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.category_name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => deleteuserFunc()}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div>
    );
};

export default FollowUpHistory;
