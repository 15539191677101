import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const deleteCategory = getBaseUrl() + "admin/deleteCategory";
const addCategory = getBaseUrl() + "admin/media_category";
const updateCategory = getBaseUrl() + "admin/media_category";


// Diet api 
const followup_list = getBaseUrl() + "/user/getallsleadassignbyid";
const followup_status = getBaseUrl() + "/user/leadfollowup";

const follow_up_action =getBaseUrl() + "/user/createHistory";
const follow_up_history=getBaseUrl() + "/user/getHistoryById";


export const get_all_lead_assign_by_id_api = async (data) => {
  let config = {
    method: "post",
    // url: getCategories + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: followup_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};







export const update_followup_status_id = async (data) => {
  let config = {
    method: "patch",
    url: followup_status,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


// //////////////////////////////////////////////////////



export const updateCategory_api = async (data, id) => {
  let config = {
    method: "patch",
    url: updateCategory + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addCategory_api = async (data) => {
  let config = {
    method: "post",
    url: addCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteCategory_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};




// **************************************** Action Page api in follow up *********************************************************** \\


export const follow_up_action_api = async (data) => {
  let config = {
    method: "post",
    // url: getCategories + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: follow_up_action,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data:data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_follow_up_history_api = async (data) => {
  let config = {
    method: "post",
    // url: getCategories + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: follow_up_history,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};








