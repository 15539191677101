const getBaseUrl = () => {
  // return "http://143.110.245.179:3002/api/";
  return "https://api.dietmeerut.co.in/api";
};
export { getBaseUrl };

const getBaseUrl2 = () => {
  return "https://api.dietmeerut.co.in/api";
};
export { getBaseUrl2 };
