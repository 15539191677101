import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./report.module.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import { deleteTestimonial_api, getTestimonials_api, status_updateTestimonial_api } from "../api/testimonial";
import { get_all_report_api } from "../api/report";
import { get_all_admin_api } from "../api/admin";

const ReportList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allReport, setAllReport] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");

  const [allRole, setAllRole] = useState([]);
  const [date, setDate] = useState("")
  const [conversionChances, setConversionChances] = useState("");
  const [assign, setAssign] = useState("")
  const [status, setStatus] = useState("")



  console.log(allReport, "value in the reports  ------------------")

  useEffect(() => {
    fetchAllReportFunc();
    fetchAllRoleFunc();
  }, [pageCount, search]);

  const fetchAllReportFunc = async (type, data) => {
    setisLoading(true);
    const temp = {
      // page: pageCount,
      // limit: 8,
      // search: search.trim(),
      ...(type === "role" ? { facultyId: data } : {}),
      ...(type === "date" ? { date: data } : {}),
      ...(type === "conversion" ? { conversion_chances: data } : {}),
      ...(type === "status" ? { follow_up_status: data } : {})
    }
    try {

      let res = await get_all_report_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.data?.totalPages);
        setAllReport(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteroleFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteTestimonial_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletedialobbox(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const testimonial_status = async (data, e) => {
    setisLoading(true);

    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", data.status == true ? false : true);
    try {
      let res = await status_updateTestimonial_api(fd);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchalltestimonialFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  const fetchAllRoleFunc = async () => {
    setisLoading(true);
    let temp = {
      page: pageCount,
      limit: 8,
      search: search?.trim(),
    };
    try {
      let res = await get_all_admin_api(temp);
      if (res.data.status) {
        setAllRole(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("false Status!");
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className={s["role-list-title"]}>
        <h3>Report List</h3>
      </div>
      <div className="user-list-heading">

        <div className={s["user-list-search"]}>

          <div className={s["filter"]}>
            <span> Select Date:</span>
            <input type="date" placeholder="select date" name="date" onChange={(e) => fetchAllReportFunc("date", e.target.value)} />
          </div>
          <div className={s["filter"]}>
            <span>Select Conversion Chance:</span>
            <div>
              <select aria-required name="conversion chances" onChange={(e) => fetchAllReportFunc("conversion", e.target.value)} >
                <option default>
                  Select Conversion Chances
                </option>
                <option value="high">
                  High
                </option>
                <option value="medium">
                  Medium
                </option>
                <option value="low">
                  Low
                </option>
              </select>
            </div>
          </div>
          <div className={s["filter"]}>
            <span> select Assigned:</span>
            <div>
              <select
                className={s["role_btn"]}
                onChange={(e) => fetchAllReportFunc("role", e.target.value)}
              >
                <option default>Select Role</option>
                {allRole.map((data, id) => (
                  <option value={data?._id}>{data?.role}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={s["filter"]}>
            <span>Select Status:</span>
            <div>
              <select name="" id="" onChange={(e) => fetchAllReportFunc("status", e.target.value)}>
                <option value="rejected">Rejected</option>
                <option value="inprogress">Inprogress</option>
                <option value="success">Success</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Education</StyledTableCell>
              <StyledTableCell align="center">Contact</StyledTableCell>
              <StyledTableCell align="center">Follow-up Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allReport.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  <p><b>Name:</b>{row?.leadId?.name}</p>
                  <p><b>dob:</b>{row?.leadId?.dob}</p>
                  <p><b>Gender:</b>{row?.leadId?.gender}</p>
                  <p><b>Specialization:</b>{row?.leadId?.specialization}</p>
                </StyledTableCell>
                <StyledTableCell>
                  {row?.leadId?.educationInfo.map((data) => (
                    <div>
                      <p><b>College:</b>{data?.college}</p>
                      <p><b>Degree:</b>{data?.degree}</p>
                      <p><b>Year:</b>{data?.year}</p>
                      ---------------------------------------
                    </div>
                  ))}
                </StyledTableCell>
                <StyledTableCell>
                  <p><b>Email:</b>{row?.leadId?.email}</p>
                  <p><b>Phone:</b>{row?.leadId?.phone}</p>
                  <p><b>Address:</b>{row?.leadId?.address1}</p>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    className={`${row?.follow_up_status === "success" ? s.active_admin : row?.follow_up_status == "inprogress" ? s.progress_admin : s.inactive_admin}`}>

                    {row?.leadId?.follow_up_status}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allReport.length <= 0 && <DataNotFound />}
        {allReport?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>
      <DeletePopup
        open={deletedialobbox}
        name={deletename}
        close={() => setdeletedialobbox(!deletedialobbox)}
        onsubmit={() => {
          deleteroleFunc();
        }}
      />
      <FilterPopup open={false} />
      <Loder loading={isLoading} />
    </div>
  );
};

export default ReportList;
