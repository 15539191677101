import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";


let report_list = getBaseUrl() + "/user/report"




export const get_all_report_api = async (data) => {
    let config = {
        method: "post",
        url: report_list,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }

    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response;
    }
}