import React from "react";
import CategoryList from "../components/FollowUp/FollowUpList";
import FollowUpList from "../components/FollowUp/FollowUpList";

const FollowUp = () => {
  return (
    <div>
      <FollowUpList />
    </div>
  );
};

export default FollowUp;
