import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

let get_all_list = getBaseUrl() + "/superadmin/getalladmin";
let add_featureMedia = getBaseUrl() + "/superadmin/create";

let admin_update = getBaseUrl() + "/superadmin/adminupdate";

export const get_all_admin_api = async (data) => {
  let config = {
    method: "post",
    url: get_all_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return response.error;
  }
};

export const add_feature_media_api = async (data) => {
  let config = {
    method: "post",
    url: add_featureMedia,
    headers: { Authorization: `Bearer${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return response.error;
  }
};

export const update_Admin_api = (data) => {
  let config = {
    method: "post",
    url: admin_update,
    headers: { Auhorization: `Bearer${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return response.error;
  }
};
