import React from "react";
import AddFollowUp from "../components/FollowUp/AddFollowUp";

const AddFollowPage = () => {
  return (
    <div>
      <AddFollowUp/>
    </div>
  );
};

export default AddFollowPage;
