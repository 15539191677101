import React, { useState, useEffect } from "react";
import s from "./admin.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addLanguage_api, updateLanguage_api } from "../api/language";
import RichTextEditor from "react-rte-17";
import { add_feature_media_api, update_Admin_api } from "../api/admin";

const AddAdmin = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [titleEnglish, settitleEnglish] = useState("");
  const [titleHindi, settitleHindi] = useState("");
  const [descEnglish, setdescEnglish] = useState("");
  const [descHindi, setdescHindi] = useState("");
  const [url, seturl] = useState("");
  const [tags, settags] = useState("");
  const [thumbnail, setthumbnail] = useState("");
  // const [rte, setrte] = useState(RichTextEditor.createEmptyValue());
  // const [rte2, setrte2] = useState(RichTextEditor.createEmptyValue());

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [number, setNumber] = useState();
  const [role, setRole] = useState("");

  const pagetype = location.state.pagetype;

  useEffect(() => {
    setFirstName(location?.state?.data?.first_name);
    setLastName(location?.state?.data?.last_name);
    setEmail(location?.state?.data?.email);
    setPassword(location?.state?.data?.password);
    setNumber(location?.state?.data?.phone_number);
    setRole(location?.state?.data?.role);
    // setthumbnail(location?.state?.data?.thumbnail?.url);
    // const initialEditorState = RichTextEditor.createValueFromString(
    //   location?.state?.data?.lyrics.hindi,
    //   "html"
    // );
    // const initialEditorState2 = RichTextEditor.createValueFromString(
    //   location?.state?.data?.lyrics.english,
    //   "html"
    // );
    // setrte(initialEditorState);
    // setrte2(initialEditorState2);
  }, [location]);

  const onChange1 = (value) => {
    setrte(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };
  const onChange2 = (value) => {
    setrte2(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  const create_user = async () => {
    if (pagetype == "Add") {
      // const fd = new FormData();
      // fd.append("titleEnglish", titleEnglish);
      // fd.append("titleHindi", titleHindi);
      // fd.append("descEnglish", descEnglish);
      // fd.append("descHindi", descHindi);
      // fd.append("url", url);
      // fd.append("mediaType", "audio");
      // fd.append(
      //   "lyrics",
      //   JSON.stringify({
      //     hindi: rte.toString("html"),
      //     english: rte2.toString("html"),
      //   })
      // );
      // fd.append("tags", tags);
      // fd.append("thumbnail", thumbnail);

      const temp = {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        phone_number: number,
        role: role,
      };
      try {
        let res = await add_feature_media_api(temp);
        if (res.data.status) {
          navigate("/feature-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const temp = {
        id: location?.state?.data?._id,
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        phone_number: number,
        role: role,
      };
      try {
        let res = await update_Admin_api(temp);
        if (res.data.status) {
          navigate("/feature-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="first Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="first name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="last name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="email"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  name="Phone number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Phone Number"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Password</label>
                <input
                  type="number"
                  className="form-control"
                  name="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="">Role</label>
                <select
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">select role</option>
                  <option value="admin">Admin</option>
                  <option value="receptionist">Receptionist</option>
                  <option value="teacher">Teacher</option>
                  <option value="dean">Dean</option>
                  <option value="hod">HOD</option>
                </select>
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Desc Hindi</label>
                <input
                  type="text"
                  className="form-control"
                  name="descHindi"
                  value={descHindi}
                  onChange={(e) => setdescHindi(e.target.value)}
                  placeholder="Desc Hindi"
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={url}
                  onChange={(e) => seturl(e.target.value)}
                  placeholder="url"
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Tags</label>
                <input
                  type="text"
                  className="form-control"
                  name="tags"
                  value={tags}
                  onChange={(e) => settags(e.target.value)}
                  placeholder="Tags"
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Thumbnail (URL)</label>
                <input
                  type="text"
                  className="form-control"
                  name="thumbnail"
                  value={thumbnail}
                  onChange={(e) => setthumbnail(e.target.value)}
                  placeholder="Thumbnail (URL)"
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Lyrics (Hindi)</label>
                <RichTextEditor
                  value={rte}
                  className="employment_jobdescribee"
                  onChange={onChange1}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Lyrics (English)</label>
                <RichTextEditor
                  value={rte2}
                  className="employment_jobdescribee"
                  onChange={onChange2}
                />
              </div>
            </Grid> */}
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddAdmin;
