import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./assign.module.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { get_all_admin_api, get_feature_media_list } from "../api/admin";
import {
  assign_lead_api,
  assign_student_api,
  deleteTag_api,
  get_all_tag_list,
} from "../api/allocatedlead";
import { fetchAllUsers } from "../api/Student";

const AllocatedLeadList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [search, setsearch] = useState("");
  const [pageLength, setpageLength] = useState();
  const [allRole, setAllRole] = useState([]);
  const [assignRole, setAssignedRole] = useState("")

  useEffect(() => {
    fetchallUserFunc();
    fetchAllRoleFunc();
  }, [pageCount, search]);


  const fetchallUserFunc = async () => {
    setisLoading(true);
    let temp = {
      page: pageCount,
      limit: 8,
      search: search?.trim(),
    };
    try {
      let res = await fetchAllUsers(temp);
      if (res.data.status) {
        setAllUser(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("false Status!");
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  async function deleteTagFunc() {
    setisLoading(true);

    try {
      let res = await deleteTag_api(deleteId);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallTagFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  const fetchAllRoleFunc = async () => {
    setisLoading(true);
    let temp = {
      page: pageCount,
      limit: 8,
      search: search?.trim(),
    };
    try {
      let res = await get_all_admin_api(temp);
      if (res.data.status) {
        setAllRole(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("false Status!");
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  const assingnRoleFunc = async (e, row) => {
    let temp = {
      leadId: row?._id,
      facultyId: e.target.value,
    };
    let res = await assign_lead_api(temp);
    try {
      if (res?.data?.status) {
        fetchallUserFunc();
        fetchAllRoleFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "danger", msg: res?.data?.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  return (
    <>
      <div className="">
        <div className="beat_heading">
          <div className={s["user-list-heading"]}>
            <div className="user-list-title">
              <h3>Assign List</h3>
            </div>
            {/* <div className={s["user-list-search"]}>
              <div className={s["search-box"]}>
                <span style={{ paddingRight: "0.5rem" }}>
                  <BiSearch size={23} />
                </span>
                <input
                  type="text"
                  spellCheck="false"
                  onChange={(e) => {
                    setpageCount(1);
                    setsearch(e.target.value);
                  }}
                  placeholder="Search by name..."
                />
              </div>
              <div className={s["filter-btn"]}>
                <span style={{ paddingRight: "2px" }}>
                  <BiFilter size={20} />
                </span>
                Filter
              </div>
            </div> */}
          </div>
          {/* <div className="beat_left">
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/add-tag", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> Assign
            </div>
          </div> */}
        </div>
        <div className="beat_table">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">User Details</StyledTableCell>
                {/* <StyledTableCell align="center">Date of Birth</StyledTableCell> */}
                {/* <StyledTableCell align="center">Number</StyledTableCell> */}
                <StyledTableCell align="center">Contact</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>

                <StyledTableCell align="center">Role</StyledTableCell>
                {/* <StyledTableCell align="center">status</StyledTableCell> */}
                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
              </TableRow>
            </TableHead>

            {/* <div style={{ margin: "0.5rem 0" }}></div> */}

            <TableBody>
              {allUser?.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <p><b>Name:</b>{row?.name}</p>
                    <p> <b>D.O.B:</b>{row?.dob} </p></StyledTableCell>
                  <StyledTableCell>
                    <p><b>Phone No.:</b>{row?.phone}</p>
                    <p><b>Email:</b>{row?.email} </p></StyledTableCell>
                  {/* <StyledTableCell align="center">
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={() => language_status(row)}
                      className={`${
                        row.status === true ? s.active_admin : s.inactive_admin
                      }`}
                    >
                      {row.status === true ? "Active" : "Inactive"}
                    </div>
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <div
                      className={`${row?.follow_up_status === "success" ? s.active_admin : row?.follow_up_status == "inprogress" ? s.progress_admin : s.inactive_admin}`}>

                      {row?.follow_up_status}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {
                      row?.assign === true ? `Assigned to ${(row?.facultyId?.role).toUpperCase()}` :
                        <select
                          className={s["role_btn"]}
                          onChange={(e) => assingnRoleFunc(e, row)}
                        >
                          <option default>Select Role</option>
                          {allRole.map((data, id) => (
                            <option value={data?._id}>{data?.role}</option>
                          ))}
                        </select>
                    }
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    <button className={s["assign_btn"]}>Inprogress</button>
                  </StyledTableCell> */}

                  {/* <StyledTableCell align="center">
                    <CiEdit
                      onClick={() =>
                        navigate("/add-tag", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }
                      style={{
                        fontSize: "1rem",
                        color: "var(--clr-primary)",
                        marginRight: "0.5rem",
                        cursor: "pointer",
                      }}
                    />
                    <MdDelete
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row._id);
                      }}
                      style={{
                        fontSize: "1rem",
                        color: "var(--clr-primary)",
                        cursor: "pointer",
                      }}
                    />
                  </StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {allUser?.length <= 0 && <DataNotFound />}
          {allUser?.length > 0 && (
            <div className={s["pagination"]}>
              <Pagination
                count={pageLength}
                size="large"
                style={{ color: "#D21903" }}
                onChange={(e, value) => setpageCount(value)}
                page={pageCount}
              />
            </div>
          )}
        </div>
        <Dialog
          open={deletePopup}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="true"
          onClose={() => setdeletePopup(false)}
        >
          <DialogTitle className={s.dialog_title}>
            <div>Do you want to delete {currentGroup.name}?</div>
          </DialogTitle>
          <DialogContent className={s.cardpopup_content}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                className={s.employee_gl_popup}
                onClick={() => setdeletePopup(false)}
              >
                Cancel
              </div>
              <div
                className={s.employee_gl_popup_del}
                onClick={() => deleteTagFunc()}
              >
                Delete
              </div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        <Loder loading={isLoading} />
      </div>
    </>
  );
};

export default AllocatedLeadList;
