  import React, { useState, useEffect } from "react";
  import s from "./banner.module.css";
  import { BiArrowBack } from "react-icons/bi";
  import { useNavigate } from "react-router-dom";
  import Custombutton from "../../Common/Custombutton";
  import { notificationHandler } from "../../utils/Notification";
  import { blankValidator, emailValidator } from "../../utils/Validation";
  import { useLocation } from "react-router-dom";
  import { Card, Grid } from "@mui/material";
  import { addBanner_api, updateBanner_api } from "../api/banner";
  const BannerAdd = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [title, settitle] = useState("");
    const [priority, setpriority] = useState("");
    const [redirectUrl, setredirectUrl] = useState("");
    const [file, setfile] = useState(null);
    const pagetype = location.state.pagetype;
    console.log(location);
    useEffect(() => {
      settitle(location?.state?.data?.title);
      setredirectUrl(location?.state?.data?.redirectUrl);
      setpriority(location?.state?.data?.priority);
    }, [location]);

    const Category_function = async () => {
      if (pagetype == "Add") {
        const fd = new FormData();
        fd.append("title", title);
        fd.append("priority", priority);
        fd.append("redirect_url", redirectUrl);
        fd.append("image", file);
        try {
          let res = await addBanner_api(fd);
          if (res.data.status) {
            navigate("/banner-list");
            notificationHandler({ type: "success", msg: res.data.message });
          } else {
            notificationHandler({ type: "success", msg: res.data.message });
          }
        } catch (error) {
          notificationHandler({ type: "danger", msg: error.message });
          console.log(error);
        }
      }
      if (pagetype == "Edit") {
        const fd = new FormData();
        fd.append("id", location?.state?.data?._id);
        fd.append("title", title);
        fd.append("priority", priority);
        fd.append("redirectUrl", redirectUrl);
        fd.append("image", file);
        try {
          let res = await updateBanner_api(fd);
          if (res.data.status) {
            navigate("/banner-list");
            notificationHandler({ type: "success", msg: res.data.message });
          } else {
            notificationHandler({ type: "success", msg: res.data.message });
          }
        } catch (error) {
          notificationHandler({ type: "danger", msg: error.message });
          console.log(error);
        }
      }
    };
    return (
      <>
        <div className="">
          <Card className={s["admin_container"]}>
            <div className={s["title"]} onClick={() => navigate(-1)}>
              <BiArrowBack />
              Back
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {/* <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Type</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select className="form-control" value={type} onChange={(e) => settype(e.target.value)} id="exampleFormControlSelect1" name="gender">
                        <option>Select Type </option>
                        <option value="app">App </option>
                        <option value="web">Web</option>
                      </select>
                    </div>
                  </div>
                </div>
              </Grid> */}
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Title</label>
                  <div className="mr-2">
                    <input type="text" className="form-control" name="title" placeholder="" value={title} onChange={(e) => settitle(e.target.value)} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Select Image</label>
                  <div className="mr-2">
                    <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Priority</label>
                  <div className="mr-2">
                    <input type="text" className="form-control" name="priority" placeholder="" value={priority} onChange={(e) => setpriority(e.target.value)} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">RedirectUrl</label>
                  <div className="mr-2">
                    <input
                      type="text"
                      className="form-control"
                      name="redirectUrl"
                      placeholder=""
                      value={redirectUrl}
                      onChange={(e) => setredirectUrl(e.target.value)}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className={s["form-login-btn"]} onClick={() => Category_function()}>
              <Custombutton>Submit</Custombutton>
            </div>
          </Card>
        </div>
      </>
    );
  };

  export default BannerAdd;
