import React from "react";
import TestimonialList from "../components/Testimonial/TestimonialList";

const Testimonial = () => {
  return (
    <div>
      <TestimonialList />
    </div>
  );
};

export default Testimonial;
