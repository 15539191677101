import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import s from "./sidebar.module.css";
import { FiUsers } from "react-icons/fi";
import { MdOutlineArticle, MdEvent } from "react-icons/md";
import { RiCommunityLine } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SiOpenbadges } from "react-icons/si";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { PiTagBold } from "react-icons/pi";
import logo from "../../assets/logo/diet-sidebar-logo-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import { RiAdminLine } from "react-icons/ri";
import { RiChatFollowUpLine } from "react-icons/ri";
const Sidebar = ({ open }) => {
  const navigate = useNavigate();
  // const [activeItem, setActiveItem] = useState(0);

  const [menuItems, setmenuItems] = useState([
    {
      title: "Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },

    {
      title: "Admin",
      icons: <MdEvent size={20} />,
      navigate: "admin-list",
      show: false,
      suItems: [],
    },
    {
      title: "Lead",
      icons: <RiAdminLine size={20} />,
      navigate: "leads",
      show: false,
      subItems: [],
    },
    {
      title: "Allocated Lead",
      icons: <PiTagBold size={20} />,
      navigate: "allocate-lead-list",
      show: false,
      subItems: [],
    },
    {
      title: "Lead Follow Up",
      icons: <RiChatFollowUpLine size={20} />,
      navigate: "followup-list",
    },
    {
      title: "Reports",
      icons: <MdEvent size={20} />,
      navigate: "report-list",
      show: false,
      subItems: [],
    },
    // {
    //   title: "Students",
    //   icons: <FiUsers size={20} />,
    //   navigate: "students",
    //   show: false,
    //   subItems: [],
    // },
    // {
    //   title: "Live Darshan",
    //   icons: <MdEvent size={20} />,
    //   navigate: "live-darshans",
    //   show: false,
    //   subItems: [],
    // },
    // {
    //   title: "Library media",
    //   icons: <MdEvent size={20} />,
    //   navigate: "library-media",
    //   show: false,
    //   subItems: [],
    // },

    // {
    //   title: "Banner",
    //   icons: <MdEvent size={20} />,
    //   navigate: "banner-list",
    //   show: false,
    //   subItems: [],
    // },

    // {
    //   title: "App Data",
    //   icons: <PiTagBold size={20} />,
    //   navigate: "app-data",
    //   show: false,
    //   subItems: [],
    // },
    // {
    //   title: "Testimonial",
    //   icons: <MdEvent size={20} />,
    //   navigate: "testimonial-list",
    //   show: false,
    //   subItems: [],
    // },
    // {
    //   title: "Events",
    //   icons: <MdEvent size={20} />,
    //   navigate: "event-list",
    //   show: false,
    //   subItems: [],
    // },
  ]);


  return (
    <>
      <section className={s["sidebar"]}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // paddingBlock: "18px",
            background: "#fff",
          }}
          className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`
            }`}
        >
          <img
            onClick={() => navigate("/dashboard")}
            src={logo}
            alt="logo"
            draggable="false"
            style={{ width: "42%", cursor: "pointer" }}
          />
        </div>
        {menuItems.map((data, i) => (
          <div >
            <div key={i}
              className={s["sidebar-content"]}
              onClick={() => navigate(`/${data?.navigate}`)}
            >
              <div className={s["sidebar-item"]}>
                <div className="sidebaricons">{data.icons}</div>
                <div
                  className={
                    open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`
                  }
                >
                  {data.title}
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Sidebar;
