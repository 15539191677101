import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

const dash_list =  getBaseUrl() + "/user/home"

export const get_dash_list_api =async()=>{
    let config={
        method:"post",
        url:dash_list,
        headers : {Authorization : `Bearer ${Cookies.get("token")}`}
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}