import React, { useState, useEffect } from "react";
import s from "./appdata.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addLanguage_api, updateLanguage_api } from "../api/language";
import RichTextEditor from "react-rte-17";
import { get_all_appdata_list } from "../api/appdata";
import Loder from "../../Loder/Loder";

const AppDataEdit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aboutUs, setAboutUs] = useState(RichTextEditor.createEmptyValue());
  const [termsOfUse, setTermOfUse] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [privacy, setPrivacy] = useState(RichTextEditor.createEmptyValue());
  const [version, setversion] = useState(RichTextEditor.createEmptyValue());
  const [contact, setContact] = useState(RichTextEditor.createEmptyValue());
  const [isLoading, setisLoading] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  useEffect(() => {
    getAppDataFunc();
  }, []);

  const getAppDataFunc = async () => {
    setisLoading(true);
    let temp = {
      page: pageCount,
      limit: 8,
      //   search: search?.trim(),
    };
    try {
      let res = await get_all_appdata_list(temp);

      if (res?.data?.status) {
        res?.data?.data?.appData.map((data) => {
          if (data?.key == "about_us") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setAboutUs(initialEditorState);
          }
          if (data?.key == "terms_of_use") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setTermOfUse(initialEditorState);
          }
          if (data?.key == "privacy_&_policy") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setPrivacy(initialEditorState);
          }
          if (data?.key == "content_policy") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setContact(initialEditorState);
          }
          if (data?.key == "version") {
            const initialEditorState = RichTextEditor?.createValueFromString(
              data?.value,
              "html"
            );
            setversion(initialEditorState);
          }
        });
        setisLoading(false);
      } else {
        console.log("false Status!");
        setisLoading(false);
      }
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  const create_user = async () => {
    const fd = new FormData();
    // fd.append(
    //   "lyrics",
    //   JSON.stringify({
    //     hindi: rte.toString("html"),
    //     english: rte2.toString("html"),
    //   })
    // );
    const temp = {
      value: a,
    };
    try {
      let res = await updateLanguage_api(fd, location?.state?.data?._id);
      if (res.data.status) {
        getAppDataFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  return (
    <>
      {!isLoading && (
        <div className="">
          <Card className={s["admin_container"]}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">About_Us</label>
                    <RichTextEditor
                      value={aboutUs}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user()}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Terms_of_Use</label>
                    <RichTextEditor
                      value={termsOfUse}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user()}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Privacy_&_Policy</label>
                    <RichTextEditor
                      value={privacy}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user()}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
              <div className={s["grid_design"]}>
                <Grid item xs={12}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Contact_Policy</label>
                    <RichTextEditor
                      value={contact}
                      className="employment_jobdescribee"
                    />
                  </div>
                </Grid>
                <div
                  className={s["form-login-btn"]}
                  onClick={() => create_user()}
                >
                  <Custombutton>Submit</Custombutton>
                </div>
              </div>
            </Grid>
          </Card>
        </div>
      )}

      <Loder loading={isLoading} />
    </>
  );
};

export default AppDataEdit;
