

import React, { useState, useEffect } from "react";
import s from "./lead.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";

import {
  Card,
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import { user_add_api, user_update_api } from "../api/Student";
import { MdDelete, MdOutlineAddCard } from "react-icons/md";

const AddLead = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [dob, setdob] = useState("");
  const [email, setemail] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [userlocation, setuserlocation] = useState("");
  const [place_of_birth, setplace_of_birth] = useState("");
  const [number, setnumber] = useState("");
  const [number2, setnumber2] = useState("");
  const [gender, setgender] = useState("");
  const [userId, setuserId] = useState("");
  const [profileImg, setprofileImg] = useState(null);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [pincode, setPincode] = useState();
  const [cityName, setCityName] = useState();
  const [status, setStatus] = useState();
  const [specialization, setSpecialization] = useState("");



  const [studentData, setStudentData] = useState([{ college: '', degree: '', class: '', year: '' }])

  // const dataToSend = [...studentData];

  console.log(studentData, "data in the student list form----------------------")



  const addRecord = () => {
    setStudentData([...studentData, { college: '', degree: '', class: '', year: '' }]);
  };
  const deleteRow = (index) => {

    console.log(index)
    if (index == 0) {
      setStudentData(studentData)
    } else {
      let newArray = studentData.filter((data, key) => key !== index);
      setStudentData(newArray);
    }
  };


  // console.log(dataToSend, "data ---dataToSend----------------------------------------------------------------")

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...studentData];
    updatedData[index][name] = value;
    setStudentData(updatedData);
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(studentData);
  // };

  const pagetype = location.state.pagetype;
  console.log(location.state.pagetype);
  useEffect(() => {
    if (pagetype == "Edit") {
      setname(location?.state?.data?.name);
      setemail(location?.state?.data?.email);
      setdob(location?.state?.data?.dob);
      setgender(location?.state?.data?.gender);
      setnumber(location?.state?.data?.phone);
      setnumber2(location?.state?.data?.phone2);
      setAddress1(location?.state?.data?.address1);
      setAddress2(location?.state?.data?.address2);
      setPincode(location?.state?.data?.pincode);
      setCityName(location?.state?.data?.city);
      setplace_of_birth(location?.state?.data?.state);
      setStatus(location?.state?.data?.status);
      setStudentData(location?.state?.data?.educationInfo)
    }
  }, [location]);

  // const handleData = () => {
  //   const dataToSend = [...studentData]
  // }
  const create_user = async () => {
    // if (pagetype == "Add") {
    //   const fd = new FormData();
    //   fd.append("name", name);
    //   fd.append("email", email);
    //   fd.append("gender", gender);
    //   fd.append("phone", number);
    //   fd.append("dob", dob);
    //   fd.append("place_of_birth", place_of_birth);
    //   fd.append("location", userlocation);
    //   fd.append("profile_image", profileImg);
    if (pagetype == "Add") {
      const temp = {
        name: name,
        email: email,
        phone: number,
        phone2: number2,
        gender: gender,
        dob: dob,
        address1: address1,
        address2: address2,
        pincode: pincode,
        city: cityName,
        status: status,
        state: place_of_birth,
        educationInfo: studentData,
        specialization: specialization,
      };
      try {
        let res = await user_add_api(temp);
        if (res.data.status) {
          console.log(res);
          navigate("/leads");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      // const fd = new FormData();
      // fd.append("id", location?.state?.data?.id);
      // fd.append("name", name);
      // fd.append("gender", gender);
      // fd.append("dob", dob);
      // fd.append("phone", number);
      // fd.append("email", email);
      // fd.append("place_of_birth", place_of_birth);
      // fd.append("location", userlocation);
      // fd.append("profile_image", profileImg);
      const temp = {
        id: location?.state?.data?._id,
        name: name,
        email: email,
        phone: number,
        phone2: number2,
        gender: gender,
        dob: dob,
        address1: address1,
        address2: address2,
        pincode: pincode,
        city: cityPincode,
        status: status,
        state: place_of_birth,
        educationInfo: studentData,
        specialization: specialization,
      };

      try {
        let res = await user_update_api(temp);
        if (res.data.status) {
          console.log(res);
          navigate("/leads");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className={s["admin_container"]}>
        <div className={s["main-heading-content"]}>
          <div className="main-heading-right">
            <h3>User</h3>
          </div>
          <div className="main-heading-left">
            <div className={s["title"]} onClick={() => navigate(-1)}>
              <BiArrowBack />
              Back
            </div>
          </div>
        </div>
        <Card className={s["card-header"]}>
          <div className={s["title"]}>Profile Details</div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  className="form-control"
                  name="email"
                  placeholder="Email"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Mobile Number</label>
                <input
                  type="number"
                  value={number}
                  onChange={(e) => setnumber(e.target.value)}
                  className="form-control"
                  name="number"
                  placeholder="Number"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Alternate No.</label>
                <input
                  type="number"
                  value={number2}
                  onChange={(e) => setnumber2(e.target.value)}
                  className="form-control"
                  name="number"
                  placeholder="Number"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">State</label>
                <input
                  type="text"
                  value={place_of_birth}
                  onChange={(e) => setplace_of_birth(e.target.value)}
                  className="form-control"
                  name="state"
                  placeholder=" State"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Gender</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={gender}
                      onChange={(e) => setgender(e.target.value)}
                      id="exampleFormControlSelect1"
                      name="gender"
                    >
                      <option >Select gender</option>
                      <option value="Male">Male </option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Specialization</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="specialization"
                      name="specialization"
                      value={specialization}
                      onChange={(e) => setSpecialization(e.target.value)}
                    >
                      <option id="" value=""> Select Specialization</option>

                      <option id="" value="B.Tech - Aeronautical Engineering"> B.Tech - Aeronautical Engineering </option>

                      <option id="" value="B.Tech - Agricultural Engineering"> B.Tech - Agricultural Engineering </option>

                      <option id="" value="B.Tech - Automobile Engineering"> B.Tech - Automobile Engineering </option>

                      <option id="" value="B.Tech - Bio-Medical Engineering"> B.Tech - Bio-Medical Engineering </option>

                      <option id="" value="B.Tech - Biotechnology"> B.Tech - Biotechnology </option>

                      <option id="" value="B.Tech - Chemical Engineering"> B.Tech - Chemical Engineering </option>

                      <option id="" value="B.Tech - Civil Engineering"> B.Tech - Civil Engineering </option>

                      <option id="" value="B.Tech - Computer Engineering"> B.Tech - Computer Engineering </option>

                      <option id="" value="B.Tech - Dairy Technology"> B.Tech - Dairy Technology </option>

                      <option id="" value="B.Tech - Electrical Engineering"> B.Tech - Electrical Engineering </option>

                      <option id="" value="B.Tech - Electronics and Communication Engineering"> B.Tech - Electronics and Communication Engineering </option>

                      <option id="" value="B.Tech - Automotive Technology"> B.Tech - Automotive Technology </option>

                      <option id="" value="B.Tech - Food Technology"> B.Tech - Food Technology </option>

                      <option id="" value="B.Tech - Information Technology"> B.Tech - Information Technology </option>

                      <option id="" value="B.Tech - Mechanical Engineering"> B.Tech - Mechanical Engineering </option>

                      <option id="" value="B.Tech - Mechatronics Engineering"> B.Tech - Mechatronics Engineering </option>

                      <option id="" value="B.Tech - Petroleum Engineering"> B.Tech - Petroleum Engineering </option>

                      <option id="" value="B.Tech - Robotics and Automation Engineering"> B.Tech - Robotics and Automation Engineering </option>

                      <option id="" value="B.Tech - Mathematics and Computing"> B.Tech - Mathematics and Computing </option>

                      <option id="" value="B.Tech. LE - Aeronautical Engineering"> B.Tech. LE - Aeronautical Engineering </option>

                      <option id="" value="B.Tech. LE - Agricultural Engineering"> B.Tech. LE - Agricultural Engineering </option>

                      <option id="" value="B.Tech. LE - Automobile Engineering"> B.Tech. LE - Automobile Engineering </option>

                      <option id="" value="B.Tech. LE - Biomedical Engineering"> B.Tech. LE - Biomedical Engineering </option>

                      <option id="" value="B.Tech. LE - Biotechnology"> B.Tech. LE - Biotechnology </option>

                      <option id="" value="B.Tech. LE - Chemical Engineering"> B.Tech. LE - Chemical Engineering </option>

                      <option id="" value="B.Tech. LE - Civil Engineering"> B.Tech. LE - Civil Engineering </option>

                      <option id="" value="B.Tech. LE - Computer Engineering"> B.Tech. LE - Computer Engineering </option>

                      <option id="" value="B.Tech. LE - Dairy Technology"> B.Tech. LE - Dairy Technology </option>

                      <option id="" value="B.Tech. LE - Information Technology"> B.Tech. LE - Information Technology </option>

                      <option id="" value="B.Tech. LE - Electrical Engineering"> B.Tech. LE - Electrical Engineering </option>

                      <option id="" value="B.Tech. LE - Electronics and Communication Engineering"> B.Tech. LE - Electronics and Communication Engineering </option>

                      <option id="" value="B.Tech. LE - Food Technology"> B.Tech. LE - Food Technology </option>

                      <option id="" value="B.Tech. LE - Mechanical Engineering"> B.Tech. LE - Mechanical Engineering </option>

                      <option id="" value="B.Tech. LE - Mechatronics Engineering"> B.Tech. LE - Mechatronics Engineering </option>

                      <option id="" value="B.Tech. LE - Petroleum Engineering"> B.Tech. LE - Petroleum Engineering </option>

                      <option id="" value="B.Tech. LE - Robotics and Automation"> B.Tech. LE - Robotics and Automation </option>

                      <option id="" value="IE - B.Tech - Aeronautical Engineering In Association with NDC"> IE - B.Tech - Aeronautical Engineering In Association with NDC </option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Address 1</label>
                <input
                  type="text"
                  className="form-control"
                  name="address1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  placeholder="Address 1"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Address 2</label>
                <input
                  type="text"
                  className="form-control"
                  name="address2"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  placeholder="Address 2"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={dob}
                  onChange={(e) => setdob(e.target.value)}
                  max={new Date().toISOString().slice(0, 10)}
                  className="form-control"
                  placeholder="Enter DOB"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Pincode</label>
                <input
                  type="number"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  className="form-control"
                  name="pincode"
                  placeholder="Pincode"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">City Name</label>
                <input
                  type="text"
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                  className="form-control"
                  name=" city Name"
                  placeholder=" city Name"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Status</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      id="exampleFormControlSelect1"
                      name="status"
                    >
                      <option default>select Status </option>
                      <option value="true">True </option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label htmlFor="">Education Qualification</label>
                <Table className="form-control">
                  <TableHead>
                    <TableCell align="center">College/University</TableCell>
                    <TableCell align="center">Specilization</TableCell>
                    <TableCell align="center">Class</TableCell>
                    <TableCell align="center">Year</TableCell>
                    <TableCell align="center">Add Row</TableCell>
                  </TableHead>
                  {studentData?.map((record, index) => (
                    <TableBody>

                      <TableCell align="center">
                        <input
                          type="text"
                          name="college"
                          className="form-control"
                          value={record.college}
                          onChange={(event) => handleInputChange(index, event)}
                          placeholder="School Name"
                        />{" "}
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          name="degree"
                          className="form-control"
                          value={record.degree}
                          onChange={(event) => handleInputChange(index, event)}
                          placeholder="course Name"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          name="class"
                          className="form-control"
                          value={record.class}
                          onChange={(event) => handleInputChange(index, event)}
                          placeholder="Class"
                        />{" "}
                      </TableCell>
                      <TableCell align="center">
                        <input
                          type="text"
                          name="year"
                          className="form-control"
                          value={record.year}
                          onChange={(event) => handleInputChange(index, event)}
                          placeholder="Year"
                        />{" "}
                      </TableCell>
                      <TableCell align="center" className={s["action_btn"]}>
                        <div className={s["action_btn"]}>
                          <button className={s["add_btn"]} onClick={addRecord}><MdOutlineAddCard size={20} /></button>
                          <button className={s["delete_btn"]} onClick={() => deleteRow(index)}><MdDelete size={20} /></button>
                        </div>
                      </TableCell>
                    </TableBody>))}

                </Table>
                {/* <div className={s["submit_btn"]}>
                    <button onClick={handleData}>Submit</button>
                  </div> */}
                {/* <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="name....."
                /> */}
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="  mr-2">
                  <input type="file" accept="image/*" onChange={(e) => setprofileImg(e.target.files[0])} className="form-control" />
                </div>
              </div>
            </Grid> */}
          </Grid>
          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton> {pagetype === "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddLead;
