import React from "react";
import LeadList from "../components/Lead/Lead";

const Leads = () => {
  return (
    <>
      <LeadList />
    </>
  );
};

export default Leads;
