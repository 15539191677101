import React, { useState, useEffect } from "react";
import s from "./followup.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addBanner_api, updateBanner_api } from "../api/banner";
import { follow_up_action_api } from "../api/followup";
const ActionPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [date, setDate] = useState("");
    const [conversionChances, setConversionChances] = useState("");
    const [remark, setRemark] = useState("");
    const [followUp, setFollowUp] = useState("");

    const pagetype = "Add"


    const actionFunc = async () => {
        if (!blankValidator(date)) {
            alert("Invalid Date")
            return
        }
        if (!blankValidator(conversionChances)) {
            alert("Fill Conversion Chances")
            return
        }
        if (!blankValidator(followUp)) {
            alert("Fill Follow Up Data")
            return
        }
        let temp = {
            id: location?.state?.data?._id,
            historyData: {
                date: date,
                conversion_chances: conversionChances,
                follow_required: followUp,
                remarks: remark
            }
        }
        try {
            let res = await follow_up_action_api(temp);
            if (res.data.status) {
                navigate("/followup-list");
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                notificationHandler({ type: "success", msg: res.data.message });
            }
        } catch (error) {
            notificationHandler({ type: "danger", msg: error.message });
            console.log(error);
        }

    };
    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"> Next follow Up Date</label>
                                <div className="mr-2">
                                    <input type="date" className="form-control" name="date" placeholder="select date" value={date} onChange={(e) => setDate(e.target.value)} />
                                </div>
                            </div>
                        </Grid>
                        {/* <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid> */}
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Conversion chances</label>
                                <div className="mr-2">
                                    <select aria-required className="form-control" name="conversion chances" value={conversionChances} onChange={(e) => setConversionChances(e.target.value)} >
                                        <option default>
                                            Select Conversion Chances
                                        </option>
                                        <option value="high">
                                            High
                                        </option>
                                        <option value="medium">
                                            Medium
                                        </option>
                                        <option value="low">
                                            Low
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Follow up required</label>
                                <div className="mr-2">
                                    <select aria-required className="form-control" name="follow up" placeholder="" value={followUp} onChange={(e) => setFollowUp(e.target.value)} >
                                        <option default>Select Follow up Required</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Remarks</label>
                                <div className="mr-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="remarks"
                                        placeholder="remark"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => actionFunc()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ActionPage;
