import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./followup.module.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import {
  deleteCategory_api,
  get_all_lead_assign_by_id_api,
  status_update_categories_api,
  update_followup_status_id,
} from "../api/followup";

const FollowUpList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [followUp, setFollowUp] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [status1, setStatus1] = useState("");

  const [student, setStudent] = useState([]);

  console.log(
    followUp,
    "-------------------------------------------------------------------data"
  );
  // console.log(
  //   status1,
  //   "--------------------------------------value of status in the list"
  // );
  useEffect(() => {
    fetchallcategoriesFunc();
    // statusUpdateApi();
  }, [pageCount, search]);

  async function fetchallcategoriesFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await get_all_lead_assign_by_id_api(temp);
      if (res.data.status) {
        setFollowUp(res?.data?.data);
        // setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      setisLoading(false)
      console.log(error);
    }
  }
  async function statusUpdateApi(e, row) {

    setisLoading(true);
    try {
      const temp = {
        id: row?._id,
        leadId: row?.leadId?._id,
        follow_up_status: e.target.value,
      };
      let res = await update_followup_status_id(temp);
      if (res.data.status) {
        setStatus1(res?.data?.data?.follow_up_status);
        fetchallcategoriesFunc();
        // setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }
  // useEffect(() => {
  //   setStudent(followUp?.studentId);
  // }, [followUp]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteuserFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteCategory_api(temp);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallcategoriesFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  // const categories_status = async (data) => {
  //   setisLoading(true);
  //   let temp = {
  //     id: data.id,
  //     status: data.status == true ? false : true,
  //   };
  //   try {
  //     let res = await status_update_categories_api(temp);
  //     console.log(res);
  //     if (res.data.status) {
  //       setisLoading(false);
  //       fetchallcategoriesFunc();
  //       notificationHandler({ type: "success", msg: res.data.message });
  //     } else {
  //       setisLoading(false);
  //       notificationHandler({ type: "danger", msg: res.data.message });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     notificationHandler({ type: "danger", msg: error.message });
  //   }
  // };

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Lead Follow-Up List</h3>
          </div>
          {/* <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by name..."
              />
            </div>
            <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div>
          </div> */}
        </div>
        {/* <div className="beat_left">
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/add-category", {
                state: {
                  pagetype: "Add",
                },
              })
            }
          >
            <IoMdAdd />
            Follow-Up
          </div>
        </div> */}
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Student</StyledTableCell>
              <StyledTableCell align="center">Assigned By</StyledTableCell>
              <StyledTableCell align="center">Faculty</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
              <StyledTableCell align="center">Follow up</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              {/* <StyledTableCell align="center">Action</StyledTableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {followUp?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  <div>
                    <p>
                      <b> Name:</b>{row?.leadId?.name}
                    </p>
                    <p> <b>Email:</b>{row?.leadId?.email}</p>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <p>
                    <b>
                      {" "}
                      Name:  </b>{row?.assignBy?.first_name}{" "}
                    {row?.assignBy?.last_name}

                  </p>
                  <p><b>Email:</b>{row?.assignBy?.email}</p>
                  <p><b>Role:</b>{row?.assignBy?.role}</p>
                  <p><b>Number:</b>{row?.assignBy?.phone_number}</p>
                </StyledTableCell>
                <StyledTableCell>
                  <p>
                    <b>
                      {" "}
                      Name:</b>{row?.facultyId?.first_name}{" "}
                    {row?.facultyId?.last_name}

                  </p>
                  <p> <b>Email:</b>{row?.facultyId?.email}</p>
                  <p><b>Role:</b>{row?.facultyId?.role}</p>
                  <p><b>Number:</b>{row?.facultyId?.phone_number}</p>
                </StyledTableCell>
                <StyledTableCell>
                  <button className={s["action_btn"]} onClick={() => navigate("/action-page", { state: { data: row } })}>
                    Action
                  </button>
                </StyledTableCell>
                <StyledTableCell>
                  {
                    row?.history &&
                    <button className={s["action_btn"]} onClick={() => navigate("/history-page", { state: { data: row } })}>History</button>
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                  // className={`${
                  //   row?.follow_up_status === "Rejected"
                  //     ? s.inactive_admin
                  //     : row?.follow_up_status === "Inprogress"
                  //     ? s.progress_admin
                  //     : s.active_admin
                  // }`}
                  >
                    {/* <select
                      className={s["status_select"]}
                      onChange={(e) => statusUpdateApi(e, row)}
                    >
                      <option value="">{row?.follow_up_status}</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Inprogress">Inprogress</option>
                      <option value="Success">Success</option>
                    </select> */}
                    <select
                      // class="form-select"
                      className={`${row?.leadId?.follow_up_status === "rejected"
                        ? s.inactive_admin
                        : row?.leadId?.follow_up_status === "inprogress"
                          ? s.progress_admin
                          : s.active_admin
                        } ${s["option"]}`}
                      style={{
                        border: "none",
                        fontSize: "14px",
                        outline: "none",
                        color: "black",
                        fontWeight: "600",
                        padding: "8px",
                        cursor: "pointer"
                      }}
                      value={row?.leadId?.follow_up_status}
                      onChange={(e) => statusUpdateApi(e, row)}
                    // aria-label="Default select example" 
                    >
                      {/* <option defaultValue>{row?.leadId?.follow_up_status}</option> */}
                      <option style={{ backgroundColor: "#f83838" }} value="rejected">Rejected</option>
                      <option style={{ backgroundColor: "#f9fd13" }} value="inprogress">Inprogress</option>
                      <option style={{ backgroundColor: "#36d200" }} value="success">Success</option>
                    </select>
                  </div>
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/followup-list", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletePopup(true);
                      setcurrentGroup(row);
                      setdeleteId(row.id);
                    }}
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {followUp?.length <= 0 && <DataNotFound />}
        {followUp?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => setpageCount(value)}
              page={pageCount}
            />
          </div>
        )}
      </div>
      <Dialog
        open={deletePopup}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth="true"
        onClose={() => setdeletePopup(false)}
      >
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.category_name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div
              className={s.employee_gl_popup}
              onClick={() => setdeletePopup(false)}
            >
              Cancel
            </div>
            <div
              className={s.employee_gl_popup_del}
              onClick={() => deleteuserFunc()}
            >
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
    </div>
  );
};

export default FollowUpList;
