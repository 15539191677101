import React, { useState, useEffect } from "react";
import s from "./event.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addTestimonial_api, updateTestimonial_api } from "../api/testimonial";
import { addevent_api, updateevent_api } from "../api/event";
const EventAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [date, setdate] = useState("");
  const [desc, setdesc] = useState("");
  const [url, seturl] = useState("");
  const [price, setprice] = useState("");
  const [file, setfile] = useState(null);
  const [eventlocation, seteventlocation] = useState("");

  const pagetype = location.state.pagetype;

  useEffect(() => {
    setname(location?.state?.data?.name);
    setdate(location?.state?.data?.date);
    setdesc(location?.state?.data?.desc);
    seturl(location?.state?.data?.url);
    setprice(location?.state?.data?.price);
    seteventlocation(location?.state?.data?.location);
  }, [location]);

  const addevent_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("location", eventlocation);
      fd.append("thumbnail", file);
      fd.append("date", date);
      fd.append("desc", desc);
      fd.append("url", url);
      fd.append("price", price);
      try {
        let res = await addevent_api(fd);
        if (res.data.status) {
          navigate("/event-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("location", eventlocation);
      fd.append("thumbnail", file);
      fd.append("date", date);
      fd.append("desc", desc);
      fd.append("url", url);
      fd.append("price", price);
      fd.append("id", location?.state?.data?._id);

      try {
        let res = await updateevent_api(fd, location?.state?.data?._id);
        if (res.data.status) {
          console.log(res);
          navigate("/event-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={eventlocation}
                  onChange={(e) => seteventlocation(e.target.value)}
                  placeholder="location"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Date</label>
                <input type="date" className="form-control" name="date" value={date} onChange={(e) => setdate(e.target.value)} placeholder="date" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <input type="text" className="form-control" name="desc" value={desc} onChange={(e) => setdesc(e.target.value)} placeholder="description" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Thumbnail URL</label>
                <input type="text" className="form-control" name="file" value={file} onChange={(e) => setfile(e.target.value)} placeholder="Thumbnail URL" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Price</label>
                <input type="text" className="form-control" name="price" value={price} onChange={(e) => setprice(e.target.value)} placeholder="price" />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => addevent_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default EventAdd;
